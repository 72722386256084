<template>
	<el-form-item :label="formItem.name + '：'" :key="`inputBox-${formItem.identifier}`" :prop="propVal">
		<el-input v-model="formVal[formItem.identifier]" placeholder="请填写">
			<span slot="suffix">{{ formItem.dataType.specs.unitName }}</span>
		</el-input>
	</el-form-item>
</template>

<script>
export default {
	name: 'inputBox',
	props: {
		formItem: {},
		formVal: {},
		parentId: {},
	},
	computed: {
		propVal() {
			return this.parentId + '.' + this.formItem.identifier
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.el-input__suffix {
		right: 6px !important;
	}
}
</style>
