<template>
	<div class="open-account-setting page">
		<gc-header header-data="openAccountSetting" />
		<div class="page-main" v-loading.lock="fullLoading">
			<template v-if="deviceTypeList.length > 0">
				<div class="page-main-left">
					<gc-sub-title icon="icon-shebei1" text="设备列表" />
					<div class="device-type-list">
						<div
							class="device-type-item"
							v-for="item in deviceTypeList"
							:key="item.deviceTypeId"
							:class="{ active: deviceTypeId === item.deviceTypeId }"
							@click="handleDeviceTypeCheck(item)"
						>
							{{ item.deviceTypeName }}
						</div>
					</div>
				</div>
				<div class="page-main-right" v-loading="loading">
					<template v-if="configList.length > 0">
						<div class="config-list">
							<el-form
								ref="configForm"
								label-position="left"
								:model="formModel"
								:rules="currentRules"
								:disabled="status === 'preview'"
							>
								<el-checkbox-group v-model="checkedKeys">
									<div
										class="config-item form-item-group"
										v-for="item in configList"
										:key="item.cmdModelId"
									>
										<el-checkbox
											:label="item.cmdModelId"
											:key="item.cmdModelId"
											@change="handleCheckboxCheck(item)"
										>
											{{ item.detail.desc }}
										</el-checkbox>
										<div class="sub-form-item-group" :class="{ checked: Boolean(item.isCheck) }">
											<component
												v-for="formItem in item.detail.inputData.filter(
													itm => itm.uiType.display,
												)"
												:key="formItem.identifier"
												:is="formItem.uiType.type"
												:form-val="formModel[item.detail.identifier]"
												:parent-id="item.detail.identifier"
												:form-item="formItem"
												:form-rules="currentRules"
											/>
										</div>
									</div>
								</el-checkbox-group>
							</el-form>
						</div>
						<template v-if="Object.keys(configList).length > 0">
							<div
								class="btn-group"
								v-if="status === 'preview'"
								v-has="'monitor:system:init-command-set:config'"
							>
								<el-button type="primary" size="large" @click="status = 'edit'">编辑</el-button>
							</div>
							<div
								class="btn-group"
								v-else-if="status === 'edit'"
								v-has="'monitor:system:init-command-set:config'"
							>
								<el-button plain size="large" @click="handleCancel">取消</el-button>
								<el-button type="primary" size="large" @click="handleUpdateConfig">保存</el-button>
							</div>
						</template>
					</template>
					<gc-empty page-type="openAccountSettingsConfig" v-else></gc-empty>
				</div>
			</template>
			<div class="page-main-right" v-else>
				<gc-empty page-type="openAccountSettings"></gc-empty>
			</div>
		</div>
	</div>
</template>

<script>
import { apiGetDeviceTypeList, apiGetOpenAccountCommand, apiSaveOpenAccountConfig } from '@/apis/systemForTenant.api.js'
import components from './components/index.js'
import { mapActions } from 'vuex'
import { cmdModelIds } from '@/config/const'

export default {
	name: 'OpenAccountSetting',
	components: {
		...components,
	},
	props: {},
	data() {
		return {
			deviceTypeList: [],
			deviceTypeId: '',
			deviceTypeConfig: {},
			status: 'edit', //  edit / preview
			formModel: {},
			configList: [],
			rules: {},
			loading: false,
			fullLoading: false,
			checkedKeys: [],
			defaultCheckedKeys: [],
		}
	},
	computed: {
		currentRules() {
			let curRules = {}
			this.checkedKeys.forEach(key => {
				curRules = {
					...curRules,
					...this.rules[key],
				}
			})
			return curRules
		},
		isolationOrgId() {
			return this.$store.getters.isolationOrgId
		},
	},
	watch: {
		isolationOrgId() {
			this.getDeviceTypeList()
		},
	},
	created() {
		cmdModelIds.map(id => {
			this.$watch(
				() => this.formModel[id]?.modifyMarkerBit,
				val => {
					const targetIndex = this.configList.findIndex(item => item.cmdModelId === id)
					this.markerBitChange(targetIndex, val)
				},
			)
		})
	},
	activated() {
		this.getDeviceTypeList()
	},
	methods: {
		...mapActions({
			judgeNeedPickOrg: 'user/judgeNeedPickOrg',
		}),
		markerBitChange(index, id) {
			const inputData = this.configList[index]?.detail?.inputData || []
			const markerBit = inputData.find(item => item.identifier === 'modifyMarkerBit')
			const specs = markerBit?.uiType?.specs || {}
			const name = specs[id]
			inputData.forEach(data => {
				if (Object.values(specs).includes(data.name)) {
					data.uiType.display = name === '所有参数'
				}
			})
			if (name && name !== '所有参数') {
				inputData.forEach(data => {
					if (data.name === name) {
						data.uiType.display = true
					}
				})
			}
		},
		handleCancel() {
			this.$refs?.configForm.resetFields()
			this.checkedKeys = this._.cloneDeep(this.defaultCheckedKeys)
			this.status = 'preview'
		},
		handleCheckboxCheck(item) {
			this.$set(item, 'isCheck', !item.isCheck)
			this.$nextTick(() => {
				this.$refs.configForm.clearValidate()
			})
			// this.$refs.configForm.validateField(this.checkedKeys);
		},
		_initFormModel() {
			Object.keys(this.configList).forEach(key => {
				let { configInfo, detail, cmdModelId } = this.configList[key]
				configInfo = configInfo || {}
				const { inputData } = detail || {}
				const obj = {},
					ruleObj = {}
				const getInitialVal = data => {
					const {
						dataType: { defaultValue },
						uiType: { type, specs },
					} = data
					if (type === 'radio') {
						return defaultValue || Object.keys(specs)[0]
					} else if (type === 'inputBox') {
						return defaultValue || ''
					}
				}
				const getRuleItem = data => {
					const {
						dataType: { reg, regMsg },
						uiType,
						desc,
					} = data
					const required = !Object.keys(uiType).includes('required')
					const regExp = reg ? new RegExp(reg) : null
					const message = regMsg || '请输入' + desc
					const _rules = [
						{
							validator: function (rule, value, callback) {
								if (!value && value !== 0 && value != '00' && required) {
									callback(new Error(message))
								} else if (value && regExp && !regExp.test(value)) {
									callback(new Error(message))
								}
								callback()
							},
						},
					]
					return required
						? [
								{
									required: required,
									message,
								},
						  ].concat(_rules)
						: _rules
				}
				inputData.forEach(item => {
					obj[item.identifier] = getInitialVal(item)

					this.$set(ruleObj, cmdModelId + '.' + item.identifier, getRuleItem(item))
				})
				this.$set(this.formModel, cmdModelId, { ...obj, ...configInfo })
				this.$set(this.rules, cmdModelId, ruleObj)
			})
		},
		handleUpdateConfig() {
			this.judgeNeedPickOrg().then(res => {
				if (res) {
					this.$refs.configForm.validate(valid => {
						if (valid) {
							let cmdModelConfigList = []

							// this.checkedKeys.forEach((key) => {
							//   const config = this.formModel[key];
							//   cmdModelConfigList.push({
							//     cmdModelId: key,
							//     configInfo: config,
							//     isCheck: 1,
							//   });
							// });
							this.configList.forEach(item => {
								const { cmdModelId, isCheck } = item
								const config = this.formModel[cmdModelId]
								cmdModelConfigList.push({
									cmdModelId,
									configInfo: config,
									isCheck: Number(isCheck),
								})
							})
							apiSaveOpenAccountConfig({
								deviceTypeId: this.deviceTypeId,
								cmdModelConfigList,
							}).then(() => {
								this.$message.success('操作成功')
								this.status = 'preview'
							})
						}
					})
				}
			})
		},
		handleDeviceTypeCheck({ deviceTypeId }) {
			this.judgeNeedPickOrg().then(res => {
				if (res) {
					this.loading = true
					this.deviceTypeId = deviceTypeId
					this.configList = []
					apiGetOpenAccountCommand(deviceTypeId)
						.then(res => {
							this.configList = res
							this.checkedKeys = this.configList
								.filter(item => Boolean(item.isCheck))
								.map(item => item.cmdModelId)
							this.defaultCheckedKeys = this._.cloneDeep(this.checkedKeys)
							this._initFormModel()
						})
						.finally(() => {
							this.status = 'preview'
							this.loading = false
						})
				}
			})
		},
		getDeviceTypeList() {
			this.judgeNeedPickOrg().then(res => {
				if (res) {
					this.fullLoading = true
					apiGetDeviceTypeList({
						tenantId: 1,
					})
						.then(records => {
							this.deviceTypeList = records?.length ? records : []
							records?.length && this.handleDeviceTypeCheck(records[0])
						})
						.finally(() => {
							this.fullLoading = false
						})
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.page-main-right {
	display: flex;
	flex-direction: column;
	.config-list {
		flex: 1;
		overflow: scroll;
		::v-deep {
			.el-checkbox__label {
				font-size: 14px;
				font-weight: 600;
				color: #222222 !important;
				line-height: 21px;
			}
		}
		.sub-form-item-group {
			margin: 24px 0 22px 14px;
			display: flex;
			flex-wrap: wrap;
			flex-flow: flow;

			::v-deep .el-form-item {
				flex: 0 0 calc(50% - 10px);

				box-sizing: border-box;
				display: flex;
				&:nth-child(2n) {
					margin-left: 20px;
				}
				.el-form-item__content {
					flex: 1;
				}
			}
		}
	}
	.btn-group {
		margin-top: 23px;
		flex: 0 0 40px;
	}
}
.page-main-left {
	display: flex;
	flex-direction: column;

	.device-type-list {
		flex: 1;
		overflow-y: auto;
		@include base-scrollbar(-6px);
		margin-top: 38px;
		.device-type-item {
			padding-left: 13px;
			margin-bottom: 10px;
			height: 40px;
			line-height: 40px;
			background: #f0f0f0;
			border-radius: 8px;
			font-size: 14px;
			color: #666666;
			box-sizing: border-box;
			&.active {
				background: #ebedfa;
				color: #4d6bff;
			}
		}
	}
}
</style>
