import request from '@/utils/request'
import { proxy as baseURL } from '@/config'

// 查询设备类型列表
export const apiGetDeviceTypeList = data => {
	return request({
		url: '/monitor/deviceType/tenant/device-type',
		method: 'GET',
		params: data,
		baseURL,
		needJointIsolation: true,
	})
}

// 获取开户指令配置信息
export const apiGetOpenAccountCommand = deviceTypeId => {
	return request({
		url: `/monitor/tenant/command/config/open/account/${deviceTypeId}`,
		method: 'GET',
		baseURL,
		pending: true,
		needJointIsolation: true,
	})
}

export const apiSaveOpenAccountConfig = data => {
	return request({
		url: `/monitor/tenant/command/config/open/account`,
		method: 'POST',
		baseURL,
		data,
		needJointIsolation: true,
	})
}
